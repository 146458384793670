.react-calendar {
  /* width: 550px;  */
  /* height:250px; */
  /* max-width: 100%; */
  background: transparent;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 0em;
  /* border:1px solid lightgray; */
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* ---------- The date element */
.react-calendar button {
  margin: 10;
  border: 0;
  /* width:10px; */
  /* height:250%; */
  outline: none;
  /* border:1px solid red; */
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  height: 44px;
  margin-bottom: em;
  background: transparent;
  font-size: 12px;
  color: #2f4660;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: transparent;
  color: #2f4660;
  /* border: 1px solid red; */
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: transparent;
}
.react-calendar__navigation button[disabled] {
  background-color: red;
}

/* Week day classname */
.react-calendar__month-view__weekdays {
  text-align: center;
  /* text-transform: uppercase; */
  /* font-weight: bold; */
  font-size: 0.75em;
  margin: 1em 0 0.5em 0.5em;
  /* text-decoration:underline; */
  color: #2f4660;
  font-family: Usual;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
  /* color: green; */
  /* text-decoration:underline; */
  /* border: 1px solid red; */
}

abbr {
  text-decoration: none;
  /* color:#AFB6BE; */
  /* border: 1px solid blue; */
  font-family: Usual;
}

.react-calendar__month-view__days__day {
  color: #2f4660;
}
.react-calendar__month-view__days__day--weekend {
  color: #afb6be;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__tile {
  /* max-width: 100%; */
  box-sizing: border-box;
  display: inline-block;
  text-align: center;
  border-radius: 5px;
  padding: 1em;
  background: transparent;
  font-size: 12px;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #2f4660;
  color: #f0f0f0;
}

.react-calendar__tile--now {
  /* background: #ffff76; */
  color: #6ad3d1;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #2f4660;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #2f4660;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #2f4660;
  /* border:1px solid red; */
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}
